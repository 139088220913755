//
// The first three layers are stricly based on ITCSS!
//

// Settings
@import "../../../../node_modules/@gymondo/frontend-core/css/00-settings/index";

// Tools
@import "../../../../node_modules/@gymondo/frontend-core/css/01-tools/index";

// Generics
// This is the first layer that creates acutal CSS code
@import "../../../../node_modules/@gymondo/frontend-core/css/02-generics/index";

// End of ITCSS

//
// Make sure that all general helper classes (eg program colors)
// are included *after* the atoms, because they will (or should) have
// the same specificity like the buttons for exmple
//

.bannerPartnerUser {
  display: block;
  position: relative;
  width: 100%;
  height: 50px;
  top: 62px;
  background-color: gym-get-color(primary, 1);
  text-align: center;
  color: #ff7f66;
  font-size: 16px;
  letter-spacing: .2px;
  padding: 0 10px;
  line-height: 50px;
  @include font-weight(bold);
  @media only screen and (max-width: 767px) {
    height: auto;
    line-height: 18px;
    padding: 12px 12px 14px;
    box-sizing: border-box;
    top: 56px;
  }
}

.leFloid {
  background-image: url('../../../assets/LeFloid-banner.jpg');
  background-repeat: no-repeat;
  background-position: 70px;
  position: relative;
  display: flex;
  top: 62px;
  background-color: #b0d1e4;
  height: 200px;

  @media #{$media-xs-only} {
    background-position: -70px;
    background-size: 250px;
  }

  .bannerText {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 415px;
      margin: auto;
      row-gap: gym-get-spacing(xs);

      @media #{$media-xs-only} {
        margin-right: 0;
        width: 227px;
        padding-right: gym-get-spacing(s);

        .title {
          font-size: gym-get-font-size(m);
        }
      }
  }

  .button {
      text-decoration: none;
  }
}