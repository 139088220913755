//
// The first three layers are stricly based on ITCSS!
//

// Settings
@import "../../../node_modules/@gymondo/frontend-core/css/00-settings/index";

// Tools
@import "../../../node_modules/@gymondo/frontend-core/css/01-tools/index";

// Generics
// This is the first layer that creates acutal CSS code
@import "../../../node_modules/@gymondo/frontend-core/css/02-generics/index";

// End of ITCSS

//
// Make sure that all general helper classes (eg program colors)
// are included *after* the atoms, because they will (or should) have
// the same specificity like the buttons for exmple
//

.headerText {
    z-index: 5;
    padding-bottom: gym-get-spacing(l);
}

.logo {
    @media #{$media-xs-only} {
        margin-bottom: gym-get-spacing(m);
        margin-top: gym-get-spacing(m);
    }
}

.seal {
    padding-left: gym-get-spacing(xl);
}

.sealXs {
    padding-left: 0;
    margin-bottom: gym-get-spacing(xs) !important;
    margin-top: 0 !important;
}

.description {
    max-width: 400px !important;

    @media #{$media-xs-only} {
        font-size: gym-get-font-size(xxs) !important;
        line-height: gym-get-spacing(m) !important;
        margin-bottom: gym-get-spacing(s) !important;
    }
}

.mobile {
    @media #{$media-xs-only} {
        height: 129vh !important;
    }
}


