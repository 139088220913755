@import '~@gymondo/frontend-core/css/style.scss';
@import '~@gymondo/frontend-core/css/style-atomic.scss';

.faqs {
    .faq {
        cursor: pointer;
        padding: gym-get-spacing(m) gym-get-spacing(l);
        background-color: gym-get-color(gray, 3);
        margin-bottom: gym-get-spacing(xs);

        &:first-child {
            border-top-left-radius: 16px;
            border-top-right-radius: 16px;
        }

        &:last-child {
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }

        .question {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h6 {
                margin: 0;
                text-align: left;
            }
        }

        .answer {
            display: none;
            text-align: left;
            margin-top: gym-get-spacing(s);
            color: gym-get-color(gray, 5);
        }

        &.open {
            .arrow {
                transform: rotate(180deg);
            }

            .answer {
                display: flex;
            }
        }
    }
}
