//
// The first three layers are stricly based on ITCSS!
//

// Settings
@import "../../../node_modules/@gymondo/frontend-core/css/00-settings/index";

// Tools
@import "../../../node_modules/@gymondo/frontend-core/css/01-tools/index";

// Generics
// This is the first layer that creates acutal CSS code
@import "../../../node_modules/@gymondo/frontend-core/css/02-generics/index";

// End of ITCSS

//
// Make sure that all general helper classes (eg program colors)
// are included *after* the atoms, because they will (or should) have
// the same specificity like the buttons for exmple
//

.accordion {
  .cta {
    padding: 0;
    background-color: transparent;
    border: 0;
    cursor: pointer;

    .arrow {
      width: 12px;
      margin-left: 1rem;
      transform: rotate(90deg);
      transition: all 0.3s ease-in-out;
    }

    &.active {
      .arrow {
        transform: rotate(-90deg);
      }
    }
  }

  .list {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.active {
      max-height: 100vh;
    }
  }
}
