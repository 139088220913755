//
// The first three layers are stricly based on ITCSS!
//

// Settings
@import "../../../node_modules/@gymondo/frontend-core/css/00-settings/index";

// Tools
@import "../../../node_modules/@gymondo/frontend-core/css/01-tools/index";

// Generics
// This is the first layer that creates acutal CSS code
@import "../../../node_modules/@gymondo/frontend-core/css/02-generics/index";

// End of ITCSS

//
// Make sure that all general helper classes (eg program colors)
// are included *after* the atoms, because they will (or should) have
// the same specificity like the buttons for exmple
//

.ctaBottom {
    display: block;
    margin-top: gym-get-spacing(xxs);
}

.checkbox {
    display: flex;

    label {
        margin-top: gym-get-spacing(xs);
    }
}
